/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2025-03-18T22:19:29
 */
import type {
  ManufacturingProcessContextAdmin,
  ManufacturingProcessContextWithProcess,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Read User Manufacturing Process Contexts
 */
export const manufacturingProcessContextReadUserManufacturingProcessContexts = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ManufacturingProcessContextWithProcess[]>(
    { url: `/api/v1/manufacturing-process-context/`, method: "GET" },
    options,
  );
};
/**
 * @summary Read All Manufacturing Process Contexts
 */
export const manufacturingProcessContextReadAllManufacturingProcessContexts = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ManufacturingProcessContextAdmin[]>(
    { url: `/api/v1/manufacturing-process-context/all`, method: "GET" },
    options,
  );
};
/**
 * Update systems in a manufacturing process context.
 * @summary Update Manufacturing Process Context Systems
 */
export const manufacturingProcessContextUpdateManufacturingProcessContextSystems = (
  manufacturingProcessContextAdmin: ManufacturingProcessContextAdmin,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<unknown>(
    {
      url: `/api/v1/manufacturing-process-context/update`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: manufacturingProcessContextAdmin,
    },
    options,
  );
};
export type ManufacturingProcessContextReadUserManufacturingProcessContextsResult =
  NonNullable<
    Awaited<
      ReturnType<typeof manufacturingProcessContextReadUserManufacturingProcessContexts>
    >
  >;
export type ManufacturingProcessContextReadAllManufacturingProcessContextsResult =
  NonNullable<
    Awaited<
      ReturnType<typeof manufacturingProcessContextReadAllManufacturingProcessContexts>
    >
  >;
export type ManufacturingProcessContextUpdateManufacturingProcessContextSystemsResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof manufacturingProcessContextUpdateManufacturingProcessContextSystems
      >
    >
  >;
