import { IAppAlert, INotification, IPrinterSocket, IUserSocket } from "@/interfaces";
import { MainState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { wsUrl } from "@/env";
import type {
  ActionDatabaseTable,
  Eventlog,
  File,
  GetPluginVersionResponse,
  ImageItem,
  MachineDeviceVariantDatabaseTable,
  MacroWithPrinterId,
  ManufacturingProcessContextWithProcess,
  ManufacturingProcessDatabaseTable,
  Material,
  MenderReadMenderSystems200,
  Notification,
  Printer,
  PrintJob,
  StripePricing,
  StripeProduct,
  StripeSubscription,
  System,
  SystemJob,
  Tag,
  TagType,
  TaskStatus,
  User,
} from "@/api-generated/api-generated.schemas";

export const mutations = {
  setOriginalURL(state: MainState, payload: string) {
    state.originalURL = payload;
  },
  setToken(state: MainState, payload: string) {
    state.token = payload;
  },
  setLoggedIn(state: MainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setLogInError(state: MainState, payload: boolean) {
    state.logInError = payload;
  },
  setSignUpError(state: MainState, payload: boolean) {
    state.signUpError = payload;
  },
  setUserProfile(state: MainState, payload: User) {
    state.userProfile = payload;
  },
  setDashboardMiniDrawer(state: MainState, payload: boolean) {
    state.dashboardMiniDrawer = payload;
  },
  setDashboardShowDrawer(state: MainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addAlert(state: MainState, payload: IAppAlert) {
    state.alerts.push(payload);
  },
  removeAlert(state: MainState, payload: IAppAlert) {
    state.alerts = state.alerts.filter((alert) => alert !== payload);
  },
  setSystems(state: MainState, payload: System[]) {
    state.systems = payload;
  },
  updateSystemState(state: MainState, payload: { id: number; systemState: string }) {
    // find the system with the given id and update its state
    const index = state.systems.findIndex((system) => system.id === payload.id);
    if (index !== -1) {
      // create a new system object with the updated state
      const updatedSystem = {
        ...state.systems[index],
        state: payload.systemState.toLowerCase(),
      };
      // replace the old system with the updated system in the systems array
      state.systems.splice(index, 1, updatedSystem);
    }
  },
  async setSystemSockets(state: MainState) {
    // Function to connect to a WebSocket for a system
    const connectWebSocket = async (system: System) => {
      // Check if there's already a socket for this system in state
      const systemSocket = state.systemSockets.find(
        (systemSocket: IPrinterSocket) => systemSocket && systemSocket.id === system.id,
      );

      // If there's no socket or the existing socket is not open
      if (!systemSocket || systemSocket.socket.readyState !== WebSocket.OPEN) {
        // Create a promise to resolve the WebSocket connection
        const socketPromise = new Promise<WebSocket>((resolve) => {
          // Check if there's an existing open socket for this system
          const existingSocket = systemSocket?.socket;

          // If an existing open socket is available, resolve with it
          if (existingSocket && existingSocket.readyState === WebSocket.OPEN) {
            resolve(existingSocket);
          } else {
            // Otherwise, create a new WebSocket connection
            const socket = new WebSocket(
              `${wsUrl}/api/v1/ws/client?token=${system.auth_key}`,
            );

            // Listen for the WebSocket to open and resolve with it
            socket.addEventListener("open", () => {
              resolve(socket);
            });
          }
        });

        // Create a new system socket with the resolved WebSocket
        const newSystemSocket: IPrinterSocket = {
          id: system.id,
          auth_key: system.auth_key,
          socket: await socketPromise,
        };

        // Return the new system socket
        return newSystemSocket;
      }

      // If there's an open socket for this system, return it
      return systemSocket;
    };

    // Create an array of promises to connect to WebSockets for all systems
    const systemSocketPromises = state.systems.map(connectWebSocket);

    // Resolve all the promises concurrently and update the system sockets in state
    const resolvedSystemSockets = await Promise.all(systemSocketPromises);
    state.systemSockets = resolvedSystemSockets;
  },
  async setSystemSocket(state: MainState, system: Printer) {
    const connectWebSocket = async (system: Printer) => {
      // Check if there's already a socket for this system in state
      const systemSocket = state.systemSockets.find(
        (systemSocket: IPrinterSocket) => systemSocket && systemSocket.id === system.id,
      );

      // If there's no socket or the existing socket is not open
      if (!systemSocket || systemSocket.socket.readyState !== WebSocket.OPEN) {
        // Create a promise to resolve the WebSocket connection
        const socketPromise = new Promise<WebSocket>((resolve) => {
          // Check if there's an existing open socket for this system
          const existingSocket = systemSocket?.socket;

          // If an existing open socket is available, resolve with it
          if (existingSocket && existingSocket.readyState === WebSocket.OPEN) {
            resolve(existingSocket);
          } else {
            // Otherwise, create a new WebSocket connection
            const socket = new WebSocket(
              `${wsUrl}/api/v1/ws/client?token=${system.auth_key}`,
            );

            // Listen for the WebSocket to open and resolve with it
            socket.addEventListener("open", () => {
              resolve(socket);
            });
          }
        });

        // Create a new system socket with the resolved WebSocket
        const newSystemSocket: IPrinterSocket = {
          id: system.id,
          auth_key: system.auth_key,
          socket: await socketPromise,
        };

        // Return the new system socket
        return newSystemSocket;
      }

      // If there's an open socket for this system, return it
      return systemSocket;
    };
    try {
      // use connectWebSocket to connect to the WebSocket for the system
      const systemSocket = await connectWebSocket(system);
      // drop the existing system socket from state and add the new one
      state.systemSockets = state.systemSockets.filter(
        (systemSocket: IPrinterSocket) => systemSocket && systemSocket.id !== system.id,
      );
      state.systemSockets[system.id] = systemSocket as IPrinterSocket;
    } catch (error) {
      console.error(
        "Error setting system socket: ",
        error,
        "with system sockets: ",
        state.systemSockets,
        "with system: ",
        system,
      );
    }
  },
  async setUserSocket(state: MainState, auth_key: string) {
    // Function to connect to a WebSocket for a user
    const connectWebSocket = async () => {
      // Check if there's already a socket for this system in state
      const userSocket = state.userSocket;

      // If there's no socket or the existing socket is not open
      if (!userSocket || userSocket.socket.readyState !== WebSocket.OPEN) {
        // Create a promise to resolve the WebSocket connection
        const socketPromise = new Promise<WebSocket>((resolve) => {
          // Check if there's an existing open socket for this system
          let existingSocket: WebSocket | undefined;
          if (userSocket) {
            existingSocket = userSocket.socket;
          }

          // If an existing open socket is available, resolve with it
          if (existingSocket && existingSocket.readyState === WebSocket.OPEN) {
            resolve(existingSocket);
          } else {
            // Otherwise, create a new WebSocket connection
            //get token from local storage
            const socket = new WebSocket(`
            ${wsUrl}/api/v1/ws/user?token=${auth_key}
            `);

            // Listen for the WebSocket to open and resolve with it
            socket.addEventListener("open", () => {
              resolve(socket);
            });
          }
        });

        // Create a new user socket with the resolved WebSocket
        const token = localStorage.getItem("token");
        const newUserSocket: IUserSocket = {
          auth_key: token || "",
          socket: await socketPromise,
        };

        // Return the new system socket
        return newUserSocket;
      }

      // If there's an open socket for this system, return it
      return userSocket;
    };

    // Await the user socket
    const userSocket = await connectWebSocket();

    // Set the user socket in state
    state.userSocket = userSocket;

    state.userSocket.socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      // If the message has notification type_id field, add a notification
      if (message.type_id) {
        this.sendNotification(message);
        state.notifications.push(message);
      }
    };
  },
  setSystemJobs(state: MainState, payload: SystemJob[]) {
    state.systemJobs = payload;
  },
  setSystemJob(state: MainState, payload: SystemJob) {
    const systemJobs = state.systemJobs.filter(
      (systemJob) => systemJob.id !== payload.id,
    );
    systemJobs.unshift(payload);
    state.systemJobs = systemJobs;
  },
  deleteSystemJob(state: MainState, payload: PrintJob) {
    const systemJobIndex = state.systemJobs.findIndex(
      (systemJob) => systemJob.id === payload.id,
    );
    state.systemJobs.splice(systemJobIndex, 1);
  },
  setMenderSystems(state: MainState, payload: MenderReadMenderSystems200) {
    state.menderSystems = payload;
  },
  setImageItems(state: MainState, payload: ImageItem[]) {
    state.imageItems = payload;
  },
  setImageItemCount(state: MainState, payload: number) {
    state.imageItemCount = payload;
  },
  setSystem(state: MainState, payload: System) {
    const index = state.systems.findIndex((system) => system.id === payload.id);
    if (index !== -1) {
      // If the system exists, update it
      state.systems[index] = payload;
    } else {
      // If the system doesn't exist, add it to the end of the list
      state.systems.push(payload);
    }
  },
  deleteSystem(state: MainState, payload: Printer) {
    const systemIndex = state.systems.findIndex((system) => system.id === payload.id);
    state.systems.splice(systemIndex, 1);
  },
  setFiles(state: MainState, payload: File[]) {
    state.files = payload;
  },
  setFile(state: MainState, payload: File) {
    const files = state.files.filter((file) => file.id !== payload.id);
    files.push(payload);
    state.files = files;
  },
  deleteFile(state: MainState, payload: File) {
    const fileIndex = state.files.findIndex((file) => file.id === payload.id);
    state.files.splice(fileIndex, 1);
  },
  deleteAllFiles(state: MainState) {
    state.files = [];
  },
  setErrorEvents(state: MainState, payload: Eventlog[]) {
    state.errorEvents = payload;
  },
  addNotification(state: MainState, payload: INotification) {
    state.notifications.push(payload);
  },
  // Send browser notification
  sendNotification(data) {
    const permission = Notification.permission;
    if (permission === "granted") {
      showNotification();
    } else if (permission === "default") {
      requestAndShowPermission();
    } else {
      console.warn("Notification permission denied");
    }

    function showNotification() {
      if (document.visibilityState === "visible") {
        return;
      }
      const title = data["subject"];
      const icon = data["link"];
      const body = "Message to be displayed";
      const notification = new Notification(title, { body, icon });
      notification.onclick = () => {
        notification.close();
        window.parent.focus();
      };
    }

    function requestAndShowPermission() {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          showNotification();
        }
      });
    }
  },
  setNotifications(state: MainState, notifications: Notification[]) {
    state.notifications = notifications;
  },
  dismissNotification(state: MainState, payload: Notification) {
    const notificationIndex = state.notifications.findIndex(
      (notification) => notification.id === payload.id,
    );
    state.notifications.splice(notificationIndex, 1);
  },
  dismissAllNotifications(state: MainState) {
    state.notifications = [];
  },
  removeNotifications(state: MainState, notifications: Notification[]) {
    state.notifications = state.notifications.filter(
      (notification) => !notifications.includes(notification),
    );
  },
  setActions(state: MainState, actions: ActionDatabaseTable[]) {
    state.actions = actions;
  },
  setAnnouncements(state: MainState, announcements: Notification[]) {
    state.announcements = announcements;
  },
  setProducts(state: MainState, stripeProducts: StripeProduct[]) {
    state.stripeProducts = stripeProducts;
  },
  setPricings(state: MainState, stripePricings: StripePricing[]) {
    state.stripePricings = stripePricings;
  },
  setUserSubscriptions(
    state: MainState,
    userStripeSubscriptions: StripeSubscription[],
  ) {
    state.userStripeSubscriptions = userStripeSubscriptions;
  },
  // *** Machine Device Variant ***
  setMachineDeviceVariants(
    state: MainState,
    machineDeviceVariants: MachineDeviceVariantDatabaseTable[],
  ) {
    state.machineDeviceVariants = machineDeviceVariants;
  },
  // *** Macros ***
  setMacros(state: MainState, macros: MacroWithPrinterId[]) {
    state.macros = macros;
  },
  setMacro(state: MainState, payload: MacroWithPrinterId) {
    const macros = state.macros.filter((macro) => macro.id !== payload.id);
    macros.push(payload);
    state.macros = macros;
  },
  deleteMacro(state: MainState, payload: MacroWithPrinterId) {
    const macroIndex = state.macros.findIndex((macro) => macro.id === payload.id);
    state.macros.splice(macroIndex, 1);
  },
  // *** Manufacturing Processes ***
  setManufacturingProcesses(
    state: MainState,
    manufacturingProcesses: ManufacturingProcessDatabaseTable[],
  ) {
    state.manufacturingProcesses = manufacturingProcesses;
  },
  setManufacturingProcessContexts(
    state: MainState,
    manufacturingProcessContexts: ManufacturingProcessContextWithProcess[],
  ) {
    state.manufacturingProcessContexts = manufacturingProcessContexts;
  },
  // *** Materials ***
  setMaterials(state: MainState, materials: Material[]) {
    state.materials = materials;
  },
  setMaterial(state: MainState, payload: Material) {
    const materials = state.materials.filter((material) => material.id !== payload.id);
    materials.push(payload);
    state.materials = materials;
  },
  deleteMaterial(state: MainState, payload: Material) {
    const materialIndex = state.materials.findIndex(
      (material) => material.id === payload.id,
    );
    state.materials.splice(materialIndex, 1);
  },
  // #region Tags
  setTags(state: MainState, tags: Tag[]) {
    state.tags = tags;
  },
  setTag(state: MainState, payload: Tag) {
    const index = state.tags.findIndex((tag) => tag.id === payload.id);
    if (index !== -1) {
      state.tags.splice(index, 1, payload);
    } else {
      state.tags.push(payload);
    }
  },
  deleteTag(state: MainState, payload: Tag) {
    const tagIndex = state.tags.findIndex((tag) => tag.id === payload.id);
    state.tags.splice(tagIndex, 1);
  },
  setTagTypes(state: MainState, tagTypes: TagType[]) {
    state.tagTypes = tagTypes;
  },
  // # endregion
  // *** Plugins ***
  setPluginVersions(state: MainState, pluginVersions: GetPluginVersionResponse) {
    state.pluginVersions = pluginVersions;
  },
  // *** Misc ***
  addTask(state: MainState, payload: TaskStatus) {
    state.tasks.push(payload);
  },
  removeTask(state: MainState, payload: TaskStatus) {
    state.tasks = state.tasks.filter((t) => t !== payload);
  },
  removeTaskByID(state: MainState, task_id: string) {
    state.tasks = state.tasks.filter((task) => task.task_id !== task_id);
  },
  setTask(state: MainState, payload: TaskStatus[]) {
    // todo matt doesn't understand what this does
    for (const task of payload) {
      const tasks = state.tasks.filter((t) => t.task_id !== task.task_id);
      tasks.push(task);
      state.tasks = tasks;
    }
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { commit } = getStoreAccessors<MainState | any, State>("");

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
// *** User ***
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetSignUpError = commit(mutations.setSignUpError);
export const commitSetOriginalURL = commit(mutations.setOriginalURL);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
// *** Alert ***
export const commitAddAlert = commit(mutations.addAlert);
export const commitRemoveAlert = commit(mutations.removeAlert);
// *** Notification ***
export const commitSetNotifications = commit(mutations.setNotifications);
export const commitRemoveNotifications = commit(mutations.removeNotifications);
export const commitDismissNotification = commit(mutations.dismissNotification);
export const commitDismissAllNotifications = commit(mutations.dismissAllNotifications);
export const commitSendNotification = commit(mutations.sendNotification);
export const commitAddNotification = commit(mutations.addNotification);
// *** Actions ***
export const commitSetActions = commit(mutations.setActions);
// *** Announcements ***
export const commitSetAnnouncements = commit(mutations.setAnnouncements);
// *** System ***
export const commitSetSystems = commit(mutations.setSystems);
export const commitSetSystemSockets = async (state: MainState) => {
  await mutations.setSystemSockets(state);
};
export const commitSetSystemSocket = async (state: MainState, system) => {
  await mutations.setSystemSocket(state, system);
};
export const commitSetUserSocket = async (state: MainState, auth_key) => {
  await mutations.setUserSocket(state, auth_key);
};
export const commitSetSystem = commit(mutations.setSystem);
export const commitDeleteSystem = commit(mutations.deleteSystem);
export const commitUpdateSystemState = commit(mutations.updateSystemState);
// *** System  job ***
export const commitSetSystemJobs = commit(mutations.setSystemJobs);
export const commitSetSystemJob = commit(mutations.setSystemJob);
export const commitDeleteSystemJob = commit(mutations.deleteSystemJob);
// *** Mender ***
export const commitSetMenderSystems = commit(mutations.setMenderSystems);
// *** Error event ***
export const commitSetErrorEvents = commit(mutations.setErrorEvents);
// *** Image item ***
export const commitSetImageItems = commit(mutations.setImageItems);
export const commitSetImageItemCount = commit(mutations.setImageItemCount);
// *** File ***
export const commitSetFiles = commit(mutations.setFiles);
export const commitSetFile = commit(mutations.setFile);
export const commitDeleteFile = commit(mutations.deleteFile);
export const commitDeleteAllFiles = commit(mutations.deleteAllFiles);
// *** Products ***
export const commitSetProducts = commit(mutations.setProducts);
// *** Pricings ***
export const commitSetPricings = commit(mutations.setPricings);
// *** Subscriptions ***
export const commitSetUserSubscriptions = commit(mutations.setUserSubscriptions);
// *** Machine Device Variants ***
export const commitSetMachineDeviceVariants = commit(
  mutations.setMachineDeviceVariants,
);
// *** Macros ***
export const commitSetMacros = commit(mutations.setMacros);
export const commitSetMacro = commit(mutations.setMacro);
export const commitDeleteMacro = commit(mutations.deleteMacro);
// *** Manufacturing Processes ***
export const commitSetManufacturingProcesses = commit(
  mutations.setManufacturingProcesses,
);
export const commitSetManufacturingProcessContexts = commit(
  mutations.setManufacturingProcessContexts,
);
// *** Materials ***
export const commitSetMaterials = commit(mutations.setMaterials);
export const commitSetMaterial = commit(mutations.setMaterial);
export const commitDeleteMaterial = commit(mutations.deleteMaterial);
// # region Tags
export const commitSetTags = commit(mutations.setTags);
export const commitSetTag = commit(mutations.setTag);
export const commitDeleteTag = commit(mutations.deleteTag);
export const commitSetTagTypes = commit(mutations.setTagTypes);
// # endregion
// *** Plugins ***
export const commitSetPluginVersions = commit(mutations.setPluginVersions);
// *** Misc ***
export const commitAddTask = commit(mutations.addTask);
export const commitRemoveTask = commit(mutations.removeTask);
export const commitRemoveTaskByID = commit(mutations.removeTaskByID);
export const commitSetTask = commit(mutations.setTask);
