import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: "#fafafa",
        surface: "#FFFFFF",
        primary: "#4C79C2",
        "primary-darken-1": "#415BAF",
        secondary: "#00FF00",
        "secondary-darken-1": "#00FF00",
        error: "#C62828",
        info: "#2196F3",
        success: "#4caf50",
        warning: "#FB8C00",
        lightgrey: "#F0F0F0",
        darkgrey: "#303030",
        outlineborder: "#c2c2c2",
        text: "#000000",
        graphaxes: "#c2c2c2",
        emphasis: "#eeeeee",
        disabled: "#9e9e9e",
      },
      dark: {
        background: "#121212",
        surface: "#1e1e1e",
        primary: "#4C79C2",
        "primary-darken-1": "#415BAF",
        secondary: "#00FF00",
        "secondary-darken-1": "#00FF00",
        error: "#C62828",
        info: "#2196F3",
        success: "#4caf50",
        warning: "#FB8C00",
        lightgrey: "#F0F0F0",
        darkgrey: "#303030",
        outlineborder: "#F0F0F0",
        text: "#ffffff",
        graphaxes: "#c2c2c2",
        emphasis: "#616161",
        disabled: "#7f7f7f",
      },
    },
  },
});
