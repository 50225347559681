/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2025-03-18T22:19:29
 */
import type {
  BodySystemJobsFinishPrintJobWithPrinter,
  SystemJob,
  SystemJobUpdate,
  SystemJobsFinishPrintJobWithPrinter200,
  SystemJobsReadSystemJobsParams,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve system_jobs.
 * @summary Read System Jobs
 */
export const systemJobsReadSystemJobs = (
  params?: SystemJobsReadSystemJobsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<SystemJob[]>(
    { url: `/api/v1/system-jobs/`, method: "GET", params },
    options,
  );
};
/**
 * Get system job by ID.
 * @summary Read System Job
 */
export const systemJobsReadSystemJob = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<SystemJob>(
    { url: `/api/v1/system-jobs/${id}`, method: "GET" },
    options,
  );
};
/**
 * Update a system job.
 * @summary Update Item
 */
export const systemJobsUpdateItem = (
  id: number,
  systemJobUpdate: SystemJobUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<SystemJob>(
    {
      url: `/api/v1/system-jobs/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: systemJobUpdate,
    },
    options,
  );
};
/**
 * Finish existing print_job.
 * @summary Finish Print Job With Printer
 */
export const systemJobsFinishPrintJobWithPrinter = (
  bodySystemJobsFinishPrintJobWithPrinter: BodySystemJobsFinishPrintJobWithPrinter,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("csv_obj", bodySystemJobsFinishPrintJobWithPrinter.csv_obj);
  formData.append("data", bodySystemJobsFinishPrintJobWithPrinter.data);

  return apiAxiosInstance<SystemJobsFinishPrintJobWithPrinter200>(
    {
      url: `/api/v1/system-jobs/remote/end-job`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
export type SystemJobsReadSystemJobsResult = NonNullable<
  Awaited<ReturnType<typeof systemJobsReadSystemJobs>>
>;
export type SystemJobsReadSystemJobResult = NonNullable<
  Awaited<ReturnType<typeof systemJobsReadSystemJob>>
>;
export type SystemJobsUpdateItemResult = NonNullable<
  Awaited<ReturnType<typeof systemJobsUpdateItem>>
>;
export type SystemJobsFinishPrintJobWithPrinterResult = NonNullable<
  Awaited<ReturnType<typeof systemJobsFinishPrintJobWithPrinter>>
>;
