/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2025-03-18T22:19:29
 */
import type {
  Notification,
  NotificationsReadNotificationsParams,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve notifications.
 * @summary Read Notifications
 */
export const notificationsReadNotifications = (
  params?: NotificationsReadNotificationsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Notification[]>(
    { url: `/api/v1/notifications/`, method: "GET", params },
    options,
  );
};
/**
 * Dismiss a notification - flag as deleted in the db.
 * @summary Delete Notification
 */
export const notificationsDeleteNotification = (
  notification: Notification[],
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Notification[]>(
    {
      url: `/api/v1/notifications/dismiss`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: notification,
    },
    options,
  );
};
/**
 * Set all notifications to viewed.
 * @summary Set Viewed Notifications
 */
export const notificationsSetViewedNotifications = (
  notification: Notification[],
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Notification[]>(
    {
      url: `/api/v1/notifications/viewed`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: notification,
    },
    options,
  );
};
export type NotificationsReadNotificationsResult = NonNullable<
  Awaited<ReturnType<typeof notificationsReadNotifications>>
>;
export type NotificationsDeleteNotificationResult = NonNullable<
  Awaited<ReturnType<typeof notificationsDeleteNotification>>
>;
export type NotificationsSetViewedNotificationsResult = NonNullable<
  Awaited<ReturnType<typeof notificationsSetViewedNotifications>>
>;
