import { actions } from "./actions";
import { getters } from "./getters";
import { MainState } from "./state";
import { mutations } from "./mutations";

const defaultState: MainState = {
  actions: [],
  alerts: [],
  announcements: [],
  dashboardMiniDrawer: false,
  dashboardShowDrawer: true,
  errorEvents: [],
  files: [],
  imageItems: [],
  imageItemCount: 0,
  isLoggedIn: null,
  logInError: false,
  machineDeviceVariants: [],
  macros: [],
  manufacturingProcesses: [],
  manufacturingProcessContexts: [],
  materials: [],
  notifications: [],
  originalURL: "",
  pluginVersions: null,
  systemJobs: [],
  systemSockets: [],
  systems: [],
  signUpError: false,
  stripePricings: [],
  stripeProducts: [],
  tasks: [],
  tags: [],
  tagTypes: [],
  token: "",
  userProfile: null,
  userSocket: null,
  userStripeSubscriptions: [],
  menderSystems: null,
};

export const mainModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
