/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2025-03-18T22:19:29
 */
import type {
  GetAsyncStatusResponse,
  GetPluginVersionResponse,
  TaskIds,
  TaskStatus,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get Async Status
 */
export const utilsGetAsyncStatus = (
  taskId: string,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<GetAsyncStatusResponse>(
    { url: `/api/v1/utils/tasks/${taskId}`, method: "GET" },
    options,
  );
};
/**
 * @summary Post Sync Status
 */
export const utilsPostSyncStatus = (
  taskIds: TaskIds,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<TaskStatus[]>(
    {
      url: `/api/v1/utils/tasks/status/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: taskIds,
    },
    options,
  );
};
/**
 * @summary Get All Task Ids By Owner
 */
export const utilsGetAllTaskIdsByOwner = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<TaskIds>(
    { url: `/api/v1/utils/tasks/user/active-ids`, method: "GET" },
    options,
  );
};
/**
 * @summary Get Plugin Version
 */
export const utilsGetPluginVersion = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<GetPluginVersionResponse>(
    { url: `/api/v1/utils/plugin-version`, method: "GET" },
    options,
  );
};
/**
 * @summary Get Gcp Usage
 */
export const utilsGetGcpUsage = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<boolean>(
    { url: `/api/v1/utils/gcp-usage/`, method: "GET" },
    options,
  );
};
export type UtilsGetAsyncStatusResult = NonNullable<
  Awaited<ReturnType<typeof utilsGetAsyncStatus>>
>;
export type UtilsPostSyncStatusResult = NonNullable<
  Awaited<ReturnType<typeof utilsPostSyncStatus>>
>;
export type UtilsGetAllTaskIdsByOwnerResult = NonNullable<
  Awaited<ReturnType<typeof utilsGetAllTaskIdsByOwner>>
>;
export type UtilsGetPluginVersionResult = NonNullable<
  Awaited<ReturnType<typeof utilsGetPluginVersion>>
>;
export type UtilsGetGcpUsageResult = NonNullable<
  Awaited<ReturnType<typeof utilsGetGcpUsage>>
>;
