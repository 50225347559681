/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2025-03-18T22:19:29
 */
import type { ManufacturingProcessDatabaseTable } from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve manufacturing processes.
 * @summary Read All Manufacturing Processes
 */
export const manufacturingProcessesReadAllManufacturingProcesses = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ManufacturingProcessDatabaseTable[]>(
    { url: `/api/v1/manufacturing-processes/all`, method: "GET" },
    options,
  );
};
export type ManufacturingProcessesReadAllManufacturingProcessesResult = NonNullable<
  Awaited<ReturnType<typeof manufacturingProcessesReadAllManufacturingProcesses>>
>;
