/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2025-03-18T22:19:29
 */
import type {
  MenderReadMenderSystems200,
  MenderReadMenderSystemsParams,
  SystemMender,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve devices from Mender.
 * @summary Read Mender Systems
 */
export const menderReadMenderSystems = (
  systemMender: SystemMender[],
  params?: MenderReadMenderSystemsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<MenderReadMenderSystems200>(
    {
      url: `/api/v1/mender/systems`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: systemMender,
      params,
    },
    options,
  );
};
export type MenderReadMenderSystemsResult = NonNullable<
  Awaited<ReturnType<typeof menderReadMenderSystems>>
>;
