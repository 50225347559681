/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2025-03-18T22:19:29
 */
import type {
  Macro,
  MacroCreate,
  MacroUpdate,
  MacroWithPrinterId,
  MacrosReadAllMacrosParams,
  MacrosReadMacrosParams,
  MacrosReadPrinterMacrosParams,
  PrinterMacro,
  PrinterMacroCreate,
  PrinterMacroUpdate,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve macros.
 * @summary Read Macros
 */
export const macrosReadMacros = (
  params?: MacrosReadMacrosParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Macro[]>(
    { url: `/api/v1/macros/`, method: "GET", params },
    options,
  );
};
/**
 * Create new macro.
 * @summary Create Macro
 */
export const macrosCreateMacro = (
  macroCreate: MacroCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Macro>(
    {
      url: `/api/v1/macros/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: macroCreate,
    },
    options,
  );
};
/**
 * Retrieve all macros.
 * @summary Read All Macros
 */
export const macrosReadAllMacros = (
  params?: MacrosReadAllMacrosParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Macro[]>(
    { url: `/api/v1/macros/all`, method: "GET", params },
    options,
  );
};
/**
 * Get macro by ID.
 * @summary Read Macro
 */
export const macrosReadMacro = (
  macroId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Macro>(
    { url: `/api/v1/macros/${macroId}`, method: "GET" },
    options,
  );
};
/**
 * Update a macro.
 * @summary Update Macro
 */
export const macrosUpdateMacro = (
  macroId: number,
  macroUpdate: MacroUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Macro>(
    {
      url: `/api/v1/macros/${macroId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: macroUpdate,
    },
    options,
  );
};
/**
 * Delete a macro.
 * @summary Delete Macro
 */
export const macrosDeleteMacro = (
  macroId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Macro>(
    { url: `/api/v1/macros/${macroId}`, method: "DELETE" },
    options,
  );
};
/**
 * Retrieve macros for a printer.
 * @summary Read Printer Macros
 */
export const macrosReadPrinterMacros = (
  params?: MacrosReadPrinterMacrosParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<MacroWithPrinterId[]>(
    { url: `/api/v1/macros/printer-macros/`, method: "GET", params },
    options,
  );
};
/**
 * Create new printer macro relationship.
 * @summary Create Printer Macro
 */
export const macrosCreatePrinterMacro = (
  printerMacroCreate: PrinterMacroCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrinterMacro>(
    {
      url: `/api/v1/macros/printer-macro/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: printerMacroCreate,
    },
    options,
  );
};
/**
 * Update a printer macro relationship.
 * @summary Update Printer Macro
 */
export const macrosUpdatePrinterMacro = (
  printerMacroId: number,
  printerMacroUpdate: PrinterMacroUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<Macro>(
    {
      url: `/api/v1/macros/printer-macro/${printerMacroId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: printerMacroUpdate,
    },
    options,
  );
};
/**
 * Delete a printer macro relationship.
 * @summary Delete Printer Macro
 */
export const macrosDeletePrinterMacro = (
  printerMacroId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrinterMacro>(
    { url: `/api/v1/macros/printer-macro/${printerMacroId}`, method: "DELETE" },
    options,
  );
};
export type MacrosReadMacrosResult = NonNullable<
  Awaited<ReturnType<typeof macrosReadMacros>>
>;
export type MacrosCreateMacroResult = NonNullable<
  Awaited<ReturnType<typeof macrosCreateMacro>>
>;
export type MacrosReadAllMacrosResult = NonNullable<
  Awaited<ReturnType<typeof macrosReadAllMacros>>
>;
export type MacrosReadMacroResult = NonNullable<
  Awaited<ReturnType<typeof macrosReadMacro>>
>;
export type MacrosUpdateMacroResult = NonNullable<
  Awaited<ReturnType<typeof macrosUpdateMacro>>
>;
export type MacrosDeleteMacroResult = NonNullable<
  Awaited<ReturnType<typeof macrosDeleteMacro>>
>;
export type MacrosReadPrinterMacrosResult = NonNullable<
  Awaited<ReturnType<typeof macrosReadPrinterMacros>>
>;
export type MacrosCreatePrinterMacroResult = NonNullable<
  Awaited<ReturnType<typeof macrosCreatePrinterMacro>>
>;
export type MacrosUpdatePrinterMacroResult = NonNullable<
  Awaited<ReturnType<typeof macrosUpdatePrinterMacro>>
>;
export type MacrosDeletePrinterMacroResult = NonNullable<
  Awaited<ReturnType<typeof macrosDeletePrinterMacro>>
>;
