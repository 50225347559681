/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2025-03-18T22:19:29
 */
import type {
  BodyImageItemsCreateImageItemWithPrintJobPredict,
  ImageItem,
  ImageItemResponse,
  ImageItemsCountImageItemsDateRangeParams,
  ImageItemsReadPrintJobImageItemsAdminParams,
  ImageItemsReadPrintJobImageItemsParams,
  ImageItemsReadPrintJobThumbnail200,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve image_items.
 * @summary Read Print Job Image Items
 */
export const imageItemsReadPrintJobImageItems = (
  printJobId: number,
  params?: ImageItemsReadPrintJobImageItemsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItemResponse[]>(
    { url: `/api/v1/images/print/${printJobId}`, method: "GET", params },
    options,
  );
};
/**
 * Retrieve image_items.
 * @summary Read Print Job Image Items Admin
 */
export const imageItemsReadPrintJobImageItemsAdmin = (
  printJobId: number,
  params?: ImageItemsReadPrintJobImageItemsAdminParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItemResponse[]>(
    { url: `/api/v1/images/print/${printJobId}/admin`, method: "GET", params },
    options,
  );
};
/**
 * Count image_items.
 * @summary Count Image Items
 */
export const imageItemsCountImageItems = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<number>(
    { url: `/api/v1/images/count`, method: "GET" },
    options,
  );
};
/**
 * Count image_items between two dates.

start_date: str format YYYY-MM-DD
end_date: str format YYYY-MM-DD
 * @summary Count Image Items Date Range
 */
export const imageItemsCountImageItemsDateRange = (
  params: ImageItemsCountImageItemsDateRangeParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<number>(
    { url: `/api/v1/images/count/date-range`, method: "GET", params },
    options,
  );
};
/**
 * Count image_items.
 * @summary Count Print Job Image Items
 */
export const imageItemsCountPrintJobImageItems = (
  printJobId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<number>(
    { url: `/api/v1/images/print/${printJobId}/count`, method: "GET" },
    options,
  );
};
/**
 * Get image_item by from print_job by count.
 * @summary Read Print Job Image Item
 */
export const imageItemsReadPrintJobImageItem = (
  printJobId: number,
  imageCount: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItemResponse>(
    { url: `/api/v1/images/print/${printJobId}/image/${imageCount}`, method: "GET" },
    options,
  );
};
/**
 * Get system job thumbnail.
 * @summary Read Print Job Thumbnail
 */
export const imageItemsReadPrintJobThumbnail = (
  printJobId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItemsReadPrintJobThumbnail200>(
    { url: `/api/v1/images/print/${printJobId}/thumbnail`, method: "GET" },
    options,
  );
};
/**
 * Create new image_item.
 * @summary Create Image Item With Print Job Predict
 */
export const imageItemsCreateImageItemWithPrintJobPredict = (
  bodyImageItemsCreateImageItemWithPrintJobPredict: BodyImageItemsCreateImageItemWithPrintJobPredict,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append(
    "image_obj",
    bodyImageItemsCreateImageItemWithPrintJobPredict.image_obj,
  );
  formData.append("data", bodyImageItemsCreateImageItemWithPrintJobPredict.data);

  return apiAxiosInstance<ImageItem>(
    {
      url: `/api/v1/images/print/predict/new-image`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
export type ImageItemsReadPrintJobImageItemsResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsReadPrintJobImageItems>>
>;
export type ImageItemsReadPrintJobImageItemsAdminResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsReadPrintJobImageItemsAdmin>>
>;
export type ImageItemsCountImageItemsResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsCountImageItems>>
>;
export type ImageItemsCountImageItemsDateRangeResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsCountImageItemsDateRange>>
>;
export type ImageItemsCountPrintJobImageItemsResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsCountPrintJobImageItems>>
>;
export type ImageItemsReadPrintJobImageItemResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsReadPrintJobImageItem>>
>;
export type ImageItemsReadPrintJobThumbnailResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsReadPrintJobThumbnail>>
>;
export type ImageItemsCreateImageItemWithPrintJobPredictResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsCreateImageItemWithPrintJobPredict>>
>;
