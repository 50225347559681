<template>
  <div id="app">
    <v-app :style="{ backgroundColor: $vuetify.theme.currentTheme.background }">
      <v-main v-if="loggedIn === null">
        <v-container fill-height>
          <v-row align="center" justify="center">
            <v-col>
              <div class="text-center">
                <div class="h1 my-5">Loading...</div>
                <v-progress-circular
                  size="100"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <router-view v-else />
      <AlertsManager></AlertsManager>
    </v-app>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import AlertsManager from "@/components/AlertsManager.vue";
import { readIsLoggedIn } from "@/store/main/getters";
import { dispatchCheckLoggedIn } from "@/store/main/actions";

@Component({
  components: {
    AlertsManager,
  },
})
export default class App extends Vue {
  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public async mounted() {
    await dispatchCheckLoggedIn(this.$store);
  }
}
</script>

<!-- Global styles -->
<style lang="scss">
@font-face {
  font-family: "DM Sans";
  src: url("@/assets/fonts/DMSans-VariableFont_opsz,wght.woff2") format("woff2");
  font-weight: 100 900;
  font-style: normal;
}
@font-face {
  font-family: "Space Mono";
  src: url("@/assets/fonts/SpaceMono-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Space Mono";
  src: url("@/assets/fonts/SpaceMono-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

$body-font-family: "DM Sans";
$title-font: "Space Mono";

.v-application {
  font-family: $body-font-family !important;
  .h1 {
    font-family: $title-font !important;
    font-weight: bold;
    font-size: 24px;
  }
  .h2 {
    font-family: $title-font !important;
    font-weight: bold;
  }
  .h3 {
    font-family: $title-font !important;
    font-weight: regular;
  }
  .h4 {
    font-family: $title-font !important;
    font-weight: regular;
  }
  .h5 {
    font-family: $title-font !important;
    font-weight: regular;
  }
  .h6 {
    font-family: $title-font !important;
    font-weight: regular;
  }
  .subtitle-1 {
    font-family: $title-font !important;
    font-weight: regular;
  }
  .subtitle-2 {
    font-family: $body-font-family !important;
    font-weight: bold;
  }
  .body-1 {
    font-family: $body-font-family !important;
    font-weight: regular;
  }
  .body-2 {
    font-family: $body-font-family !important;
    font-weight: bold;
  }
  .caption {
    font-family: $body-font-family !important;
    font-weight: regular;
  }
  .overline {
    font-family: $body-font-family !important;
    font-weight: regular;
  }
}

@import "~vuetify/dist/vuetify.min.css"; /* Import Vuetify's base styles */

/* To override <hr> color */
td,
th,
tbody,
tr,
button {
  border-width: 1.25px !important;
  border-color: rgba(0, 0, 0, 0.24) !important;
  z-index: 0 !important;
}

hr {
  border-width: 1.25px 0 0 0 !important;
  border-color: rgba(0, 0, 0, 0.24) !important;
}

.v-application .v-card {
  border: 1.25px solid rgba(0, 0, 0, 0.24) !important;
  border-color: var(--v-outlineborder-base) !important;
}

.container {
  padding: 32px !important;
}
.v-application .v-btn {
  font-family: $title-font !important;
}

.v-application .v-sheet.v-toolbar {
  border-bottom: 1.25px solid rgba(0, 0, 0, 0.24) !important;
  border-color: rgba(0, 0, 0, 0.24) !important;
}

.v-application .v-divider {
  border-color: var(--v-outlineborder-base) !important;
}

.v-application .v-navigation-drawer__content {
  border-right: 1.25px solid rgba(0, 0, 0, 0.24) !important;
  border-color: "rgba(0, 0, 0, 0.24)" !important;
}

.v-application .v-list .v-list-item--active {
  color: #4c79c2 !important;
}

.v-application .v-data-footer {
  background-color: var(--v-background-base) !important;
}

/* To override default outlines */
.v-application .v-banner .v-banner__wrapper,
.v-application .v-btn--outlined,
.v-application .v-data-footer,
.v-application .v-text-field .v-input__slot::before,
.v-application .v-text-field .v-input__slot::after
/* Add more Vuetify selectors that use the default outline color here */ {
  border-width: 1.25px !important;
  border-color: var(--v-outlineborder-base) !important;
}
.v-dialog {
  border-radius: 16px !important;
}
.v-dialog .v-card {
  border-radius: 16px !important;
}
</style>
