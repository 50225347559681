/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2025-03-18T22:19:29
 */
import type {
  System,
  SystemCreate,
  SystemUpdate,
  SystemsReadAllSystemsParams,
  SystemsReadSystemsParams,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve systems.
 * @summary Read Systems
 */
export const systemsReadSystems = (
  params?: SystemsReadSystemsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<System[]>(
    { url: `/api/v1/systems/`, method: "GET", params },
    options,
  );
};
/**
 * Create a new system.
 * @summary Create System
 */
export const systemsCreateSystem = (
  systemCreate: SystemCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<System>(
    {
      url: `/api/v1/systems/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: systemCreate,
    },
    options,
  );
};
/**
 * Retrieve a system.
 * @summary Read System
 */
export const systemsReadSystem = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<System>(
    { url: `/api/v1/systems/${id}`, method: "GET" },
    options,
  );
};
/**
 * Update a system.
 * @summary Update System
 */
export const systemsUpdateSystem = (
  id: number,
  systemUpdate: SystemUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<System>(
    {
      url: `/api/v1/systems/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: systemUpdate,
    },
    options,
  );
};
/**
 * Retrieve all systems.
 * @summary Read All Systems
 */
export const systemsReadAllSystems = (
  params?: SystemsReadAllSystemsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<System[]>(
    { url: `/api/v1/systems/all`, method: "GET", params },
    options,
  );
};
export type SystemsReadSystemsResult = NonNullable<
  Awaited<ReturnType<typeof systemsReadSystems>>
>;
export type SystemsCreateSystemResult = NonNullable<
  Awaited<ReturnType<typeof systemsCreateSystem>>
>;
export type SystemsReadSystemResult = NonNullable<
  Awaited<ReturnType<typeof systemsReadSystem>>
>;
export type SystemsUpdateSystemResult = NonNullable<
  Awaited<ReturnType<typeof systemsUpdateSystem>>
>;
export type SystemsReadAllSystemsResult = NonNullable<
  Awaited<ReturnType<typeof systemsReadAllSystems>>
>;
