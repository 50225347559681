/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2025-03-18T22:19:29
 */
import type { MachineDeviceVariantDatabaseTable } from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Read Machine Device Variants
 */
export const machineDeviceVariantReadMachineDeviceVariants = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<MachineDeviceVariantDatabaseTable[]>(
    { url: `/api/v1/machine-device-variants/`, method: "GET" },
    options,
  );
};
export type MachineDeviceVariantReadMachineDeviceVariantsResult = NonNullable<
  Awaited<ReturnType<typeof machineDeviceVariantReadMachineDeviceVariants>>
>;
