/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2025-03-18T22:19:29
 */
import type { PrinterTag, PrinterTagCreate } from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Create new tag.
 * @summary Create Printer Tag
 */
export const printerTagsCreatePrinterTag = (
  printerTagCreate: PrinterTagCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrinterTag>(
    {
      url: `/api/v1/printer-tags/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: printerTagCreate,
    },
    options,
  );
};
/**
 * Delete a tag.
 * @summary Delete Printer Tag
 */
export const printerTagsDeletePrinterTag = (
  printerTagId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<PrinterTag>(
    { url: `/api/v1/printer-tags/${printerTagId}`, method: "DELETE" },
    options,
  );
};
export type PrinterTagsCreatePrinterTagResult = NonNullable<
  Awaited<ReturnType<typeof printerTagsCreatePrinterTag>>
>;
export type PrinterTagsDeletePrinterTagResult = NonNullable<
  Awaited<ReturnType<typeof printerTagsDeletePrinterTag>>
>;
